.contact-form.contact-form-details .contact-close {
  display: none;
}

.contact-form.contact-form-details {
  background-color: var(--wl-background-color-default);
  max-height: 0;
  transition: max-height 1s ease 0;
  overflow: hidden;
}

.contact-form.contact-form-details.open {
  max-height: 999px;
  transition: max-height 1s ease 500ms;
  margin: 1px;
}

.contact-form.contact-form-details .main-form {
  padding: 0 18px 16px;
}

.contact-form.contact-form-details .main-form .title {
  display: none;
}

.contact-form.contact-form-details .message-error,
.contact-form.contact-form-details .message-sent {
  padding: 8px 18px 18px;
}

.contact-form.contact-form-details .message-error p,
.contact-form.contact-form-details .message-sent p {
  font-family: var(--wl-font-family-default);
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  color: var(--wl-text-color-two);
  margin-bottom: 10px;
  margin-top: 10px;
}

.contact-form.contact-form-details .message-sent .title {
  background-color: var(--wl-background-color-default);
}

.contact-form.contact-form-details .message-error {
  color: var(--wl-text-color-nine);
}

.contact-form.contact-form-details .message-error.hidden,
.contact-form.contact-form-details .message-sent.hidden,
.contact-form.contact-form-details .main-form.hidden {
  display: none;
}

.contact-form.contact-form-details .form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-form.contact-form-details .btn-action {
  font-family: var(--wl-font-family-default);
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-large);
  height: 40px;
  padding: 4px 13px 4px;
  font-weight: bold;
  text-transform: uppercase;
  border: 0 none;
  background: var(--wl-background-color-seven);
  box-shadow: none;
}

.contact-form.contact-form-details .btn-action:before {
  content: "";
  background: url(../../icons/Email.svg) no-repeat;
  width: 21px;
  height: 16px;
  display: inline-block;
  margin: 0 3px -2px;
}

.success-alert,
.error-alert {
  border: 1px solid var(--wl-border-color-four);
  border-radius: 5px;
  display: flex;
}

.success-alert:before {
  background-color: var(--wl-background-color-seven);
  background-image: url(../../icons/Check.svg);
  background-position: center 16px;
  background-repeat: no-repeat;
  content: '';
  width: 60px;
}

.error-alert:before {
  background-color: var(--wl-background-color-eight);
  background-image: url(../../icons/Error.svg);
  background-position: center 16px;
  background-repeat: no-repeat;
  content: '';
  width: 60px;
}

.message-box {
  background-color: var(--wl-content-background-color-default);
  border-radius: 5px;
  margin: 0;
  padding: 0 18px 0;
  width: 100%;
}

.message-title {
  font-weight: bold;
}

.boat-listings .message-box {
  margin-top: 16px;
}

.main-form label {
  margin-top: 16px;
}
