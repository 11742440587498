.btn-action {
  border-radius: 4px;
  font-size: var(--wl-font-size-large);
  line-height: var(--wl-line-height-medium);
  display: block;
  cursor: pointer;
  border: 1px solid var(--wl-border-color-five);
  padding: 12px 0;
  background-color: var(--wl-background-color-one);
  color: var(--wl-text-color-four);
  width: 100%;
  height: 50px;
}

.btn-action:hover,
.btn-action:focus,
.btn-action:active {
  border: none;
}
.contact-form.contact-form-details .btn-action:disabled {
  opacity: 0.4;
  cursor: auto;
}

.main-form .btn-action:disabled {
  opacity: 0.4;
  cursor: auto;
}

.contact-form {
  background-color: var(--wl-background-color-five);
  padding-top: 4px;
  border-radius: 3px;
  z-index: 5;
}

.contact-form .title {
  font-size: var(--wl-font-size-large);
  line-height: var(--wl-line-height-default);
  margin: 0 0 15px;
  font-weight: 700;
}

.contact-form.private-seller {
  background: transparent;
}

.contact-form .contact-tabs {
  display: none;
}

.contact-form .form-heading {
  display: block;
  margin-bottom: 12px;
  font-weight: bold;
}

.contact-form .message-sent {
  color: var(--wl-text-color-four);
}

.contact-form .message-error {
  color: var(--wl-text-color-four);
  text-align: center;
}

.contact-form .message-sent.hidden,
.contact-form .message-error.hidden {
  display: none;
}

.contact-form .message-sent p,
.contact-form .message-error p {
  margin-top: 0;
  font-size: var(--wl-font-size-small);
}
.contact-form.contact-form-results {
  z-index: 9999;
}
.contact-form.contact-form-modal {
  z-index: 99;
}

.contact-form.contact-form-modal,
.contact-form.contact-form-results {
  position: absolute;
  left: 10px;
  right: 10px;
  width: auto;
  margin: 0;
  padding: 0;
  background-color: var(--wl-content-background-color-default);
  box-shadow: 0 5px 15px var(--wl-box-shadow-two);
}

.party-container .contact-form.contact-form-modal,
.party-container .contact-form.contact-form-results {
  top: -50%;
}

.contact-form.contact-form-modal
  .title
  .contact-form.contact-form-results
  .title {
  font-weight: 700;
  margin: 5px 0 15px;
}

.contact-form.contact-form-modal.open,
.contact-form.contact-form-results.open {
  display: block;
}

.contact-form.contact-form-modal button.contact-close,
.contact-form.contact-form-results button.contact-close {
  background: url('../../icons/Close.svg') no-repeat center center transparent;
  border-width: 0;
  display: block;
  height: 14px;
  margin: 21px;
  right: 0;
  top: 0;
  position: absolute;
  width: 14px;
}
.contact-form.contact-form-modal .main-form,
.contact-form.contact-form-results .main-form {
  padding: 15px;
}

.contact-form.contact-form-modal .main-form.hidden,
.contact-form.contact-form-results .main-form.hidden {
  display: none;
}

.contact-form.contact-form-modal .message-error,
.contact-form.contact-form-results .message-error {
  color: var(--wl-text-color-nine);
  clear: both;
  padding: 0 0 15px;
  text-align: left;
  font-size: var(--wl-font-size-xx-small);
}

.contact-form.contact-form-modal .message-sent,
.contact-form.contact-form-results .message-sent {
  color: var(--wl-text-color-eight);
  padding: 15px;
}

.contact-form-container-modal {
  position: fixed;
  width: 100%;
  background-color: var(--wl-box-shadow-two);
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-form.contact-form-modal {
  left: auto;
  right: auto;
  bottom: auto;
  min-width: 350px;
}

.terms-privacy {
  color: black;
  text-align: center;
}
.terms-privacy a {
  color: black;
  padding: 0 2px;
  font-weight: bold;
  display: inline-block;
  text-decoration: none;
}
.terms-privacy a:hover {
  text-decoration: underline !important;
}

.noscroll {
  overflow: hidden;
}

@media (min-width: 500px) {
  .contact-form.contact-form-results {
    right: 22px;
    bottom: 22px;
  }
}

@media (min-width: 768px) {
  .boat-list .contact-form.contact-form-results {
    left: auto;
    right: 126px;
    bottom: 0;
    width: 280px;
  }

  .party-container .contact-form.contact-form-results {
    left: 169px;
    bottom: 0;
    width: 280px;
  }

  .boat-list .contact-form.contact-form-results:after {
    content: '';
    position: absolute;
    bottom: 19px; /* value = - border-top-width - border-bottom-width */
    right: -14px; /* controls horizontal position */
    border-width: 12px 9px 0; /* vary these values to change the angle of the vertex */
    border-style: solid;
    border-color: var(--wl-content-background-color-default) transparent;
    transform: rotate(270deg);
    /* reduce the damage in FF3.0 */
    display: block;
    width: 0;
  }

  .party-container .contact-form.contact-form-results:after {
    content: '';
    position: absolute;
    bottom: 38px; /* value = - border-top-width - border-bottom-width */
    right: 20px; /* controls horizontal position */
    border-width: 12px 9px 0; /* vary these values to change the angle of the vertex */
    border-style: solid;
    border-color: var(--wl-content-background-color-default) transparent;
    /* reduce the damage in FF3.0 */
    display: block;
    width: 0;
  }

  .party-container .contact-form.contact-form-modal,
  .party-container .contact-form.contact-form-results {
    top: initial;
    right: initial;
  }
  .party-container .contact-form.contact-form-results:after {
    left: -15px;
    transform: rotate(90deg);
  }
}

@media (min-width: 970px) {
  .contact-form.contact-form-modal button.contact-close,
  .contact-form.contact-form-results button.contact-close {
    float: right;
    width: 16px;
    height: 16px;
    margin: 15px;
  }

  .contact-form.contact-form-modal button.contact-close:hover,
  .contact-form.contact-form-results button.contact-close:hover {
    cursor: pointer;
  }

  .contact-form.contact-form-modal h5,
  .contact-form.contact-form-results h5 {
    margin: 0 0 15px;
  }
}
