.validated-input {
  position: relative;
  padding: 0;
  margin: 0 0 10px;
  display: block;
  clear: both;
  border: none;
}

.validated-input.hidden {
  display: none;
}

.validated-input label {
  display: block;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: var(--wl-font-size-default);
  line-height: var(--wl-line-height-default);
  height: 2.4em;
  width: 100%;
  z-index: 2;
  padding: 0.5em 10px;
  pointer-events: none;
  position: absolute;
  border-color: transparent;
  top: 0;
  left: 0;
  color: var(--wl-text-color-six);
}

.validated-input label.hidden {
  display: none;
  left: -999px;
}
.validated-input select,
.validated-input input[type="tel"],
.validated-input input[type="text"] {
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid var(--wl-border-color-seven);
  font-size: var(--wl-font-size-default);
  line-height: var(--wl-line-height-default);
  height: 2.4em;
  width: 100%;
  z-index: 2;
  padding: 0.5em 10px;
  -webkit-appearance: none;
  box-shadow: none;
}

.validated-input input[type="number"] {
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid var(--wl-border-color-seven);
  font-size: var(--wl-font-size-default);
  line-height: var(--wl-line-height-default);
  height: 2.4em;
  width: 100%;
  z-index: 2;
  padding: 0.5em 10px;
}

.validated-input textarea {
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid var(--wl-border-color-seven);
  font-size: var(--wl-font-size-default);
  line-height: var(--wl-line-height-default);
  width: 100%;
  z-index: 2;
  padding: 7px 0 0 10px;
  resize: vertical;
  height: 7em;
}

.validated-input textarea#comments {
  overflow-y: auto;
  resize: none;
}

.validated-input.error select,
.validated-input.error textarea,
.validated-input.error input[type="tel"],
.validated-input.error input[type="text"] {
  border: 2px solid var(--wl-border-color-six);
  padding: 6px 9px;
}

/* Details Page */
.validated-input.details {
  position: relative;
  border: 0 none;
  padding: 0;
  margin: 0 0 10px;
  box-sizing: border-box;
  width: 100%;
}

.validated-input.details.hidden {
  display: none;
}

.validated-input.details input,
.validated-input.details textarea {
  font-family: var(--wl-font-family-default);
  font-size: var(--wl-font-size-small);
  line-height: var(--wl-line-height-xx-large);
  padding: 4px 9px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--wl-border-color-four);
  border-radius: 4px;
  color: var(--wl-text-color-ten);
}

.validated-input.details textarea {
  min-height: 70px;
}

.validated-input.details.error input,
.validated-input.details.error textarea {
  border-color: var(--wl-border-color-two);
}

.validated-input.details.error input:placeholder,
.validated-input.details.error textarea:placeholder {
  color: var(--wl-text-color-ten);
}

.validated-input.details.validated-input-name,
.validated-input.details.validated-input-phone {
  width: 100%;
}

.validated-input.showLabel {
  display: flex;
  flex-direction: column;
  margin: 0 0 10px;
}

.validated-input.showLabel label {
  font-size: var(--wl-font-size-medium);
  font-family: var(--wl-font-family-one);
  font-size: 14px;
  position: relative;
  height: auto;
  padding: 0;
  margin-bottom: 2px;
  color: var(--wl-text-color-thirteen);
}
